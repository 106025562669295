const extracoinCryptoEN = {
  confirmTransctionExtracoin: "Confirm the transfer using Extracoin",
  youDoNotHaveWalletLogged: "You do not have a connected wallet",
  walletLogout: "You have disconnected your wallet",
  walletLogin: "You have connected your wallet",
  walletLoginFailed: "Error connecting wallet",
  sendTransaction: "Send transaction",
  destinyWallet: "Destiny Wallet",
  EXCQuantity: "Extracoin amount",
};

export default extracoinCryptoEN;
