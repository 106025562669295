const productInfoDictIT = {
  price: "Prezzo",
  initialPrice: "Prezzo iniziale",
  percentualOfCredit: "Percentuale di credito: ",
  minimumPurchaseAmount: "Valore minimo di acquisto",
  noDescription: "Non è stato registrato dal venditore o non ce l'ha.",
  promocionalPrice: "Prezzo promozionale: ",
  comparePrices: "Confronta i prezzi",
  descriptionProduct: "Descrizione",
  characteristics: "Caratteristiche",
  productReviews: "Recensioni del prodotto",
  storeReviews: "Recensioni del negozio",
  showMore: "Mostra di più",
  thisProductIsYours: "Questo prodotto è tuo",
  errorThisProductIsYours: "Non puoi acquistare il tuo stesso prodotto",
  questionsAndAnswers: "Domande e risposte",
  question: "Domanda",
  doQuestion: "Fai una domanda",
  doQuestionToSeller: "Chiedi al venditore",
  sendQuestion: "Invia domanda",
  askQuestionSuccess: "Domanda inviata con successo!",
  answerQuestionSuccess: "Risposta inviata con successo!",
  reply: "Rispondere",
  askQuestionTooLong: "La domanda deve avere al massimo 200 caratteri",
  askQuestionEmpty: "La domanda non può essere vuota",
  answerQuestionTooLong: "La risposta deve avere al massimo 200 caratteri",
  answerQuestionEmpty: "La risposta non può essere vuota",
  waitingResponseFromSeller: "In attesa di risposta dal venditore",
  askQuestionHasPhoneNumber: "La domanda non può contenere il numero di telefono",
  answerQuestionHasPhoneNumber: "La risposta non può contenere il numero di telefono",
  freeToItaly: "Spedizione gratuita in Italia",
  seeAtMap: "Vedi sulla mappa",
  locationOfStore: "Posizione del negozio",
};

export default productInfoDictIT;
