const extracoinCryptoPT = {
  confirmTransctionExtracoin: "Confirme a transferência utilizando Extracoin",
  youDoNotHaveWalletLogged: "Você não tem uma carteira conectada",
  walletLogout: "Você desconectou sua carteira",
  walletLogin: "Você conectou sua carteira",
  walletLoginFailed: "Falha ao conectar a carteira",
  sendTransaction: "Enviar transação",
  destinyWallet: "Carteira de destino",
  EXCQuantity: "Quantidade de Extracoin",
};

export default extracoinCryptoPT;
